import { motion } from 'framer-motion';
import React, { useMemo } from 'react';
import { useAuth } from '../../contexts/authContext';
import { useSteps } from '../../contexts/stepContext';
import {
  AdditionalInfoIcon,
  ArrowRight,
  CheckIcon,
  Container,
  StepItem,
  UserIcon,
} from './styles';

export default function Steps() {
  const { currentStep, selectedServices } = useSteps();
  const { additionalFields } = useAuth();

  const steps = useMemo(() => {
    let addFields = false;

    if (selectedServices.length === 0) {
      addFields = additionalFields;
    } else {
      addFields = selectedServices.some(s => s.ser_additional_fields === true);
    }

    const stepIcons = [
      {
        id: 1,
        icon: UserIcon,
      },
      {
        id: 2,
        icon: CheckIcon,
      },
    ];

    if (addFields) {
      stepIcons.splice(1, 0, { id: 5, icon: AdditionalInfoIcon });
    }

    return stepIcons;
  }, [additionalFields, selectedServices]);

  return (
    <Container>
      <ul>
        {steps.map((step, index) => {
          return (
            <StepItem
              key={step.id}
              selected={index < currentStep}
              as={motion.li}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ delay: 0.5 }}
            >
              <div>{React.createElement(step.icon)}</div>
              {index !== steps.length - 1 && <ArrowRight />}
            </StepItem>
          );
        })}
      </ul>
    </Container>
  );
}
