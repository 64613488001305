import React from 'react';
import logoSGR from '../../assets/logo.svg';
import logoSwint from '../../assets/swintLogo.svg';
import { Container, Content } from './styles';

const GeolocationDenied: React.FC = () => {
  return (
    <Container>
      <img src={logoSGR} alt="Logo SGRLoc" />
      <Content>
        <img src={logoSwint} alt="Logo Swint" />
        <p>
          Ops, parece que você bloqueou o acesso à sua localização! Para
          continuar, deverá permitir que saibamos onde você está.
        </p>
        <p>
          Para habilitar sua localização, acesse as{' '}
          <span>Configurações do site</span>, clicando no ícone de cadeado
          localizado antes do endereço da plataforma. Na seção{' '}
          <span>Permissões</span>, deixe a opção de <span>Local</span> como{' '}
          <span>Permitir</span>
        </p>

        <a href="/">Tentar novamente</a>
      </Content>
    </Container>
  );
};

export default GeolocationDenied;
