import React, { ButtonHTMLAttributes, ReactElement } from 'react';
import { Container, Loading } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  icon?: any;
  isLoading?: boolean;
  textLoading?: string;
}

const Button = ({
  title,
  icon: Icon,
  isLoading,
  textLoading = 'Enviando...',
  ...rest
}: ButtonProps): ReactElement => {
  return (
    <>
      <Container {...rest}>
        {isLoading ? (
          <>
            {textLoading}
            <Loading />
          </>
        ) : (
          <>
            {title} {Icon && <Icon size={20} />}
          </>
        )}
      </Container>
    </>
  );
};

export default Button;
