import styled from 'styled-components';

export const ContainerCheck = styled.div``;

export const Round = styled.div`
  position: relative;

  label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 20px;
    position: absolute;
    width: 20px;

    &::after {
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      content: '';
      height: 3px;
      left: 3px;
      opacity: 0;
      position: absolute;
      top: 5px;
      transform: rotate(-45deg);
      width: 10px;
    }

    &:hover {
      border-color: hsla(0, 100%, 0%, 0.7);
    }
  }

  input[type='checkbox'] {
    visibility: hidden;
  }

  input[type='checkbox']:checked + label {
    background-color: hsla(0, 100%, 0%, 0.7);
    border-color: hsla(0, 100%, 0%, 0.7);
  }

  input[type='checkbox']:checked + label:after {
    opacity: 1;
  }
`;
