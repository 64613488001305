/* eslint jsx-a11y/mouse-events-have-key-events: 0 */
import { useRef, useState } from 'react';

export default function Zoom({ scale, children }: any) {
  const [zoom, setZoom] = useState(false);
  const [mouseX, setMouseX] = useState<number | null>(null);
  const [mouseY, setMouseY] = useState<number | null>(null);

  const ref = useRef<HTMLDivElement>(null);

  const handleMouseOver = () => {
    setZoom(true);
  };

  const handleMouseOut = () => {
    setZoom(false);
  };

  const handleMouseMovement = (e: any) => {
    const { left: offsetLeft, top: offsetTop } =
      ref.current!.getBoundingClientRect();

    const h = ref.current!.offsetHeight;
    const w = ref.current!.offsetWidth;

    const x = ((e.pageX - offsetLeft) / w) * 100;
    const y = ((e.pageY - offsetTop) / h) * 100;

    setMouseX(x);
    setMouseY(y);
  };

  return (
    <div
      ref={ref}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onMouseMove={handleMouseMovement}
      style={{
        width: 'fit-content',
        position: 'relative',
        zIndex: 100,
        cursor: 'zoom-in',
      }}
    >
      {children}
      <div
        style={{
          transition: `transform .3s ease-out`,
          transformOrigin: `${mouseX}% ${mouseY}%`,
          transform: zoom ? `scale(${scale})` : 'scale(1.0)',
          position: 'absolute',
          pointerEvents: 'none',
          top: 0,
        }}
      >
        {children}
      </div>
    </div>
  );
}
