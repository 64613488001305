import { createElement } from 'react';
import BodyStep from '../../components/BodyStep';
import OptIn from '../../components/OptIn';
import { useAuth } from '../../contexts/authContext';
import { useSteps } from '../../contexts/stepContext';

export default function Balcao() {
  const { steps, currentStep } = useSteps();
  const { companyName, optin } = useAuth();

  return (
    <>
      {optin === 0 && <OptIn />}
      {companyName ? (
        <BodyStep>{createElement(steps[currentStep])}</BodyStep>
      ) : (
        createElement(steps[currentStep])
      )}
    </>
  );
}
