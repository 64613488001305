import styled, { createGlobalStyle } from 'styled-components';
import backgroundImg from '../assets/background.jpeg';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  html {
    height: -webkit-fill-available;
  }

  body {
    height: 100%;
    min-height: -webkit-fill-available;
    background: url(${backgroundImg}) no-repeat;
    background-size: cover;
    color: #626262;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font-family: 'Roboto', serif;
    font-size: 16px;
  }

  h1, h2, h3 h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }

  @media(max-width: 1080px) {
      html {
          font-size: 93.75%;
      }
    }
    @media(max-width: 720px) {
      html {
          font-size: 87.5%;
      }
    }
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  line-height: 2rem;
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin-bottom: 1rem;

  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;

export const MobileCam = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;

  > section {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    > section {
      width: 100%;
      height: 300px;

      section {
        height: 100%;

        p {
          margin: 0;
        }
      }
    }
  }

  button {
    margin-top: 1rem;
  }
`;
