import { ReactElement, forwardRef, Ref } from 'react';

import {
  CheckBoxWrapper,
  CheckBox,
  CheckBoxLabel,
  CheckboxTitle,
  Container,
} from './styles';

interface SwitchInputProps {
  onChange(value: boolean): void;
  width?: string;
  description?: string;
  hasTooltip?: boolean;
  tooltipMessage?: string;
  title: string;
  id: string;
  checked: boolean;
}

const ToggleSwitch = (
  { id, title, onChange, width = '100%', ...rest }: SwitchInputProps,
  ref: Ref<HTMLInputElement>,
): ReactElement => {
  return (
    <Container width={width}>
      <CheckBoxWrapper width={width}>
        <CheckboxTitle>{title}</CheckboxTitle>
        <CheckBox
          ref={ref}
          id={id}
          type="checkbox"
          onChange={e => onChange(e.target.checked)}
          {...rest}
        />
        <CheckBoxLabel htmlFor={id} />
      </CheckBoxWrapper>
    </Container>
  );
};

export default forwardRef(ToggleSwitch);
