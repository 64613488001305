import React, { forwardRef, InputHTMLAttributes, LegacyRef } from 'react';
import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  type: string;
  label?: string;
  width?: string;
}

const Input = (
  { type, width = '100%', label, required, ...rest }: InputProps,
  ref: LegacyRef<HTMLInputElement>,
) => {
  return (
    <Container {...rest} width={width}>
      {label && (
        <label>
          {label}
          {required && (
            <span
              style={{
                marginLeft: '4px',
                fontSize: '16px',
                color: 'red',
              }}
            >
              *
            </span>
          )}
        </label>
      )}
      <input type={type} ref={ref} required={required} {...rest} />
    </Container>
  );
};

export default forwardRef(Input);
