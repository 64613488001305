import React, { useCallback, useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { FaSignInAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import logoSGR from '../../assets/logo.svg';
import logoSwint from '../../assets/swintLogo.svg';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { useAuth } from '../../contexts/authContext';
import { useSteps } from '../../contexts/stepContext';
import { errorToast } from '../../utils/toast';
import { Container, Content } from './styles';

interface LoginProps {
  user: string;
  password: string;
  captcha: string | null | undefined;
}

const Login: React.FC = () => {
  const { register, handleSubmit } = useForm<LoginProps>();
  const { signIn, signInWithToken } = useAuth();
  const { handleSetLoading } = useSteps();
  const reCaptchaLoginRef = useRef<ReCAPTCHA>(null);

  const onSubmit = useCallback(
    async (data: LoginProps) => {
      handleSetLoading(true);

      const captcha = await reCaptchaLoginRef.current?.executeAsync();

      reCaptchaLoginRef.current?.reset();

      if (!captcha) {
        errorToast({ message: 'Captcha é necessário!' });
        return;
      }

      const loginData = {
        user: data.user,
        password: data.password,
        captcha,
        action: 'login',
      };

      const response = await signIn(loginData);

      if (response.error) errorToast({ message: response.message });
      handleSetLoading(false);
    },
    [signIn, handleSetLoading],
  );

  useEffect(() => {
    const verifyToken = async (token: string) => {
      handleSetLoading(true);
      const response = await signInWithToken(token);

      if (response.error) errorToast({ message: response.message });
      handleSetLoading(false);
    };

    const search = new URLSearchParams(window.location.search);

    const token = search.get('token');

    if (token) {
      verifyToken(token);
    }
  }, [signInWithToken, handleSetLoading]);

  return (
    <Container>
      <img src={logoSGR} alt="Logo SGRLoc" />
      <Content>
        <img src={logoSwint} alt="Logo Swint" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            {...register('user')}
            type="text"
            placeholder="Informe seu usuario"
            label="USUÁRIO"
          />
          <Input
            {...register('password')}
            type="password"
            placeholder="Informe sua senha"
            label="SENHA"
          />
          <div>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY_SITE || ''}
              size="invisible"
              ref={reCaptchaLoginRef}
            />
          </div>
          <Button type="submit" title="ENTRAR" icon={FaSignInAlt} />
        </form>
        <Link to="/forgotPassword">Esqueci a senha</Link>
      </Content>
    </Container>
  );
};

export default Login;
