import { createContext } from 'react';
import { StepContextData } from './types';

const StepContext = createContext<StepContextData>({
  documentNum: '',
  name: '',
  birthday: '',
  zipCode: '',
  phoneNumber: '',
  email: '',
  selfie: '',
  currentStep: 0,
  currentValidation: '',
  result: '',
  loading: false,
  loadingResult: true,
  documentType: 0,
  messageError: '',
  actionFromImage: '',
  serviceError: '',
  documents: [],
  selectedServices: [],
  requiredFields: {
    name: false,
    birth_date: false,
    image: true,
  },
  steps: [],
  handleSetDocument: () => null,
  handleSetName: () => null,
  handleSetBirthday: () => null,
  handleSetZipCode: () => null,
  handleSetPhoneNumber: () => null,
  handleSetEmail: () => null,
  handleSetImage: () => null,
  setSelectedServices: () => null,
  setResult: () => null,
  handleSetLoading: () => null,
  handleChangeCurrentStep: () => null,
  handleReturnCurrentStep: () => null,
  setCurrentStep: () => null,
  handleChangeCurrentValidation: () => null,
  clearData: () => null,
  setDocumentType: () => null,
  setMessageError: () => null,
  ImageToBase64: () => null,
  setActionFromImage: () => null,
  setServiceError: () => null,
  setRequiredFields: () => null,
  imageJwt: '',
  setImageJwt: () => null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  sendRequest: async () => {},
});

export default StepContext;
