import { FaGlasses, FaRegSquare } from 'react-icons/fa';
import {
  MdTagFaces,
  MdScreenLockRotation,
  MdCenterFocusStrong,
} from 'react-icons/md';
import { IoBulbOutline } from 'react-icons/io5';
import styled from 'styled-components';

export const ContainerInstructions = styled.div`
  width: 100%;
  height: 300px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-bottom: 1rem;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export const GlassIcon = styled(FaGlasses)`
  height: 40px;
  width: 40px;
`;

export const UserPhotoIcon = styled(MdTagFaces)`
  height: 40px;
  width: 40px;
  color: red;
`;

export const BulbIcon = styled(IoBulbOutline)`
  height: 40px;
  width: 40px;
`;

export const RotatePhoneIcon = styled(MdScreenLockRotation)`
  height: 40px;
  width: 40px;
`;

export const RegSquare = styled(FaRegSquare)`
  height: 40px;
  width: 40px;
`;

export const Focus = styled(MdCenterFocusStrong)`
  height: 40px;
  width: 40px;
`;
