import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import logoSGR from '../../assets/logo.svg';
import logoSwint from '../../assets/swintLogo.svg';
import Loading from '../../components/Loading';
import { useAuth } from '../../contexts/authContext';
import api from '../../services/api';
import { Container } from '../Login/styles';
import { Content } from './styles';

const ValidateToken: React.FC = () => {
  const { push } = useHistory();
  const { token } = useParams<{ token: string }>();
  const { setAccessDash, setData, setApiVersion } = useAuth();

  useEffect(() => {
    api
      .get('/validateDashToken', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(data => {
        setAccessDash(true);

        setData(oldValue => {
          return {
            ...oldValue,
            companyId: data.data.companyId,
            companyName: data.data.companyName,
            optin: data.data.optin,
            userName: data.data.user,
            additionalFields: data.data.additionalFields,
          };
        });

        setApiVersion(data.data.version);

        api.defaults.headers.common.authorization = `Bearer ${token}`;
      })
      .catch(() => {
        setAccessDash(false);
        push('/');
      });
  }, [token, push, setAccessDash, setApiVersion, setData]);

  return (
    <Container>
      <img src={logoSGR} alt="Logo SGRLoc" />
      <Content>
        <img src={logoSwint} alt="Logo Swint" />
        <h1>Validando token...</h1>
        <Loading withOverlay={false} />
      </Content>
    </Container>
  );
};

export default ValidateToken;
