/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import api from '../services/api';

export interface AuthState {
  token: string;
  refresh_token: string;
  companyName: string;
  userName: string;
  companyId: string;
  optin: number;
  user: string;
  additionalFields: boolean;
  canSelectServices: boolean;
}

interface SignInCredentials {
  user: string;
  password: string;
  captcha: string | null | undefined;
}

interface IErrorResponse {
  error: boolean;
  message: string;
}

interface AuthContextData {
  userName: string;
  companyName: string;
  user: string;
  apiVersion: string;
  modal: boolean;
  optin: number;
  additionalFields: boolean;
  canSelectServices: boolean;
  signIn(credentials: SignInCredentials): Promise<IErrorResponse>;
  signInWithToken(token: string): Promise<IErrorResponse>;
  signOut(): void;
  setModal: (value: boolean) => void;
  handleAcceptTerms: (
    user_Email: string,
  ) => Promise<{ result: string; message: string }>;
  setData: React.Dispatch<React.SetStateAction<AuthState>>;
  setAccessDash: (accessDash: boolean) => void;
  setApiVersion: (apiVersion: string) => void;
  accessDash: boolean;
}

interface IResponseRefreshToken {
  result: string;
  token: string;
  refresh_token: string;
  message: string;
  version: string;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const history = useHistory();

  const [apiVersion, setApiVersion] = useState('');
  const [modal, setModal] = useState(true);
  const [accessDash, setAccessDash] = useState(false);

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@SWINTbalcao:token');
    const refresh_token = sessionStorage.getItem('@SWINTbalcao:refresh_token');
    const companyName = localStorage.getItem('@SWINTbalcao:companyName');
    const userName = localStorage.getItem('@SWINTbalcao:userName');
    const companyId = localStorage.getItem('@SWINTbalcao:companyId');
    const apiV = localStorage.getItem('@SWINTbalcao:apiVersion');
    const optin = Number(localStorage.getItem('@SWINTbalcao:optin'));
    const user = localStorage.getItem('@SWINTbalcao:user');
    const additionalFields = localStorage.getItem(
      '@SWINTbalcao:additionalFields',
    );

    const canSelectServices = localStorage.getItem(
      '@SWINTbalcao:canSelectServices',
    );

    if (
      token &&
      companyName &&
      userName &&
      refresh_token &&
      companyId &&
      apiV &&
      optin &&
      user &&
      additionalFields &&
      canSelectServices
    ) {
      api.defaults.headers.common.authorization = `Bearer ${token}`;

      setApiVersion(apiV);

      return {
        token,
        companyName,
        userName,
        refresh_token,
        companyId,
        optin,
        user,
        additionalFields: additionalFields === 'true',
        canSelectServices: canSelectServices === 'true',
      };
    }

    return {} as AuthState;
  });

  const handleAcceptTerms = async (user_Email: string) => {
    const res = await api.post('/users/optin', { cre_user: user_Email });
    return res.data;
  };

  const LogIn = useCallback((token, refresh_token, apiV) => {
    sessionStorage.setItem('@SWINTbalcao:token', token);
    sessionStorage.setItem('@SWINTbalcao:refresh_token', refresh_token);
    localStorage.setItem('@SWINTbalcao:token', token);
    localStorage.setItem('@SWINTbalcao:refresh_token', refresh_token);
    localStorage.setItem('@SWINTbalcao:apiVersion', apiV);
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@SWINTbalcao:token');
    localStorage.removeItem('@SWINTbalcao:refresh_token');
    localStorage.removeItem('@SWINTbalcao:companyName');
    localStorage.removeItem('@SWINTbalcao:companyId');
    localStorage.removeItem('@SWINTbalcao:userName');
    sessionStorage.removeItem('@SWINTbalcao:token');
    sessionStorage.removeItem('@SWINTbalcao:refresh_token');
    localStorage.removeItem('@SWINTbalcao:OptIn');
    localStorage.removeItem('@SWINTbalcao:user');
    localStorage.removeItem('@SWINTbalcao:additionalFields');
    localStorage.removeItem('@SWINTbalcao:lifeProof');
    localStorage.removeItem('@SWINTbalcao:canSelectServices');

    setData({} as AuthState);
  }, []);

  const refreshToken = useCallback(async (): Promise<{
    data: IResponseRefreshToken;
    status: number;
  }> => {
    const refresh = sessionStorage.getItem('@SWINTbalcao:refresh_token');

    const res = await api
      .post('/session/refresh-token', {
        token: refresh,
      })
      .then(response => response)
      .catch(err => err.response);

    const { token, refresh_token, result, version } = res.data;

    if (result === 'error') {
      signOut();
      history.push('/');
      window.location.reload();
      return res;
    }

    setApiVersion(version || '1.0.0');
    LogIn(token, refresh_token, version || '1.0.0');
    return res;
  }, [LogIn, signOut, history]);

  const signIn = useCallback(async (loginData: SignInCredentials) => {
    api.defaults.headers.common.creuser = loginData.user;

    const res = await api
      .post('session/create', loginData)
      .then(response => response)
      .catch(err => err.response);

    if (!res) return { error: true, message: 'Erro ao realizar o login' };

    if (res.data.result === 'error' || (res.data.result === 'warn' && res))
      return { error: true, message: res.data.message };

    if (res.data.error)
      return { error: true, message: res.data.error.details[0].message };

    const {
      token,
      refresh_token,
      companyName,
      companyId,
      userName,
      version,
      optin,
      user,
      additionalFields,
      credentialLifeProof,
      canSelectServices,
    } = res.data;

    localStorage.setItem('@SWINTbalcao:token', token);
    localStorage.setItem('@SWINTbalcao:refresh_token', refresh_token);
    localStorage.setItem('@SWINTbalcao:companyName', companyName);
    localStorage.setItem('@SWINTbalcao:companyId', companyId);
    localStorage.setItem('@SWINTbalcao:userName', userName);
    localStorage.setItem('@SWINTbalcao:apiVersion', version || '1.0.0');
    sessionStorage.setItem('@SWINTbalcao:token', token);
    sessionStorage.setItem('@SWINTbalcao:refresh_token', refresh_token);
    localStorage.setItem('@SWINTbalcao:OptIn', optin);
    localStorage.setItem('@SWINTbalcao:user', user);
    localStorage.setItem('@SWINTbalcao:additionalFields', additionalFields);
    localStorage.setItem('@SWINTbalcao:lifeProof', credentialLifeProof);
    localStorage.setItem('@SWINTbalcao:canSelectServices', canSelectServices);

    api.defaults.headers.common.authorization = `Bearer ${token}`;

    setApiVersion(version || '1.0.0');
    setData({
      token,
      companyName,
      userName,
      companyId,
      refresh_token,
      optin,
      user,
      additionalFields,
      canSelectServices,
    });

    return { error: false, message: 'success' };
  }, []);

  const signInWithToken = useCallback(async (t: string) => {
    api.defaults.headers.common.authorization = `Bearer ${t}`;

    const res = await api
      .post('session/createFromToken')
      .then(response => response)
      .catch(err => err.response);

    if (!res) return { error: true, message: 'Erro ao realizar o login' };

    if (res.data.result === 'error' || (res.data.result === 'warn' && res))
      return { error: true, message: res.data.message };

    if (res.data.error)
      return { error: true, message: res.data.error.details[0].message };

    const {
      token,
      refresh_token,
      companyName,
      companyId,
      userName,
      version,
      optin,
      user,
      additionalFields,
      credentialLifeProof,
      canSelectServices,
    } = res.data;

    localStorage.setItem('@SWINTbalcao:token', token);
    localStorage.setItem('@SWINTbalcao:refresh_token', refresh_token);
    localStorage.setItem('@SWINTbalcao:companyName', companyName);
    localStorage.setItem('@SWINTbalcao:companyId', companyId);
    localStorage.setItem('@SWINTbalcao:userName', userName);
    localStorage.setItem('@SWINTbalcao:apiVersion', version || '1.0.0');
    sessionStorage.setItem('@SWINTbalcao:token', token);
    sessionStorage.setItem('@SWINTbalcao:refresh_token', refresh_token);
    localStorage.setItem('@SWINTbalcao:OptIn', optin);
    localStorage.setItem('@SWINTbalcao:user', user);
    localStorage.setItem('@SWINTbalcao:additionalFields', additionalFields);
    localStorage.setItem('@SWINTbalcao:lifeProof', credentialLifeProof);
    localStorage.setItem('@SWINTbalcao:canSelectServices', canSelectServices);

    api.defaults.headers.common.authorization = `Bearer ${token}`;

    setApiVersion(version || '1.0.0');
    setData({
      token,
      companyName,
      userName,
      companyId,
      refresh_token,
      optin,
      user,
      additionalFields,
      canSelectServices,
    });

    return { error: false, message: 'success' };
  }, []);

  useEffect(() => {
    const session = sessionStorage.getItem('@SWINTbalcao:token');

    if (!session) {
      signOut();
    }
  }, [signOut]);

  useEffect(() => {
    const interceptorId = api.interceptors.response.use(
      response => response,
      async ({ response, config }: any) => {
        const originalReq = config;

        if (response.status === 401) {
          originalReq._retry = true;
          const responseRefreshToken = await refreshToken();

          if (
            responseRefreshToken?.status === 200 &&
            responseRefreshToken?.data.token
          ) {
            originalReq.headers.authorization = `Bearer ${responseRefreshToken.data.token}`;
            api.defaults.headers.common.authorization = `Bearer ${responseRefreshToken.data.token}`;
            return axios(originalReq);
          }
        }
        return false;
      },
    );

    return () => {
      api.interceptors.response.eject(interceptorId);
    };
  }, [refreshToken]);

  return (
    <AuthContext.Provider
      value={{
        companyName: data.companyName,
        userName: data.userName,
        optin: data.optin,
        user: data.user,
        additionalFields: data.additionalFields,
        canSelectServices: data.canSelectServices,
        apiVersion,
        modal,
        signIn,
        signInWithToken,
        signOut,
        setData,
        setModal,
        handleAcceptTerms,
        setAccessDash,
        setApiVersion,
        accessDash,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error(
      "Custom hook 'useAuth' must be used within an 'AuthProvider'",
    );
  }

  return context;
}
