import styled from 'styled-components';

import {
  TiDocumentText,
  TiCameraOutline,
  TiTickOutline,
  TiChevronRight,
  TiDeleteOutline,
  TiArrowForwardOutline,
  TiBusinessCard,
  TiContacts,
} from 'react-icons/ti';
import colors from '../../styles/colors';

interface IconProps {
  selected: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: auto;

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    list-style: none;
  }
`;

export const StepItem = styled.li<IconProps>`
  display: flex;
  justify-content: space-around;
  align-items: center;

  > svg {
    color: ${props => (props.selected ? colors.aux1 : '#C2C2C2')};
    margin: 0 6px;
  }

  > div {
    transition: 1s;
    background: ${props => props.selected && colors.aux1};
    border-radius: 50%;
    height: 41px;
    width: 41px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    > svg {
      color: ${props => (props.selected ? '#FFF' : '#C2C2C2')};
    }
  }
`;

export const ArrowRight = styled(TiChevronRight)`
  width: 15px;
  height: 15px;
`;

export const InstructionsIcon = styled(TiDocumentText)`
  width: 25px;
  height: 25px;
`;

export const AdditionalInfoIcon = styled(TiContacts)`
  width: 25px;
  height: 25px;
`;

export const CameraIcon = styled(TiCameraOutline)`
  width: 25px;
  height: 25px;
`;

export const CheckIcon = styled(TiTickOutline)`
  width: 25px;
  height: 25px;
`;

export const LoginIcon = styled(TiArrowForwardOutline)`
  width: 25px;
  height: 25px;
`;

export const ErrorIcon = styled(TiDeleteOutline)`
  width: 25px;
  height: 25px;
`;

export const UserIcon = styled(TiBusinessCard)`
  width: 25px;
  height: 25px;
`;
