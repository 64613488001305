import styled from 'styled-components';

export const FormContent = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin-top: 0.5rem;
    color: #ed4c4c;
  }
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  line-height: 2rem;
  width: 100%;
  text-align: center;
  font-weight: bold;

  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;

export const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 660px) {
    grid-template-columns: 1fr;
    grid-auto-rows: auto auto;
  }
`;
