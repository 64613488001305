import styled from 'styled-components';

interface ContainerProps {
  width: string;
}

export const Container = styled.div<ContainerProps>`
  width: ${props => props.width};
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 850px) {
    width: 100%;
  }

  input {
    width: 100%;
    border: 0;
    padding: 0.5rem;
    border-bottom: 1px solid #626262;
    text-align: center;
    margin-top: 0.5rem;
    background: transparent;
  }

  label {
    font-weight: bold;
  }
`;
