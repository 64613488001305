import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api.defaults.validateStatus = status => {
  if (status !== 401) return true;

  return false;
};

export default api;
