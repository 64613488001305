import styled from 'styled-components';

export const FormContent = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin-top: 0.5rem;
    color: #ed4c4c;
  }
`;
