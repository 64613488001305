import { useSteps } from '../../contexts/stepContext';
import { Title } from '../../styles/global';
import { Content } from '../BodyStep/styles';
import Button from '../Button';
import InstructionItem from '../InstructionItem';
import {
  BulbIcon,
  ContainerInstructions,
  Focus,
  GlassIcon,
  RegSquare,
  RotatePhoneIcon,
  UserPhotoIcon,
} from './styles';

export default function Instructions({ onClose }: { onClose: () => void }) {
  const { currentValidation } = useSteps();
  return (
    <div
      style={{
        position: 'fixed',
        width: '100vw',
        minHeight: '100vh',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,0.3)',
        zIndex: 100,
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <Content>
        <Title>Instruções</Title>
        {currentValidation === 'Foto' ? (
          <ContainerInstructions>
            <InstructionItem text="Tirar uma selfie com o rosto frontal e visível">
              <UserPhotoIcon />
            </InstructionItem>
            <InstructionItem text="Busque uma boa iluminação">
              <BulbIcon />
            </InstructionItem>
            <InstructionItem text="Remova adereços como óculos e chapeu ">
              <GlassIcon />
            </InstructionItem>
            <InstructionItem text="Evite rotacionar a cãmera do dispositivo ">
              <RotatePhoneIcon />
            </InstructionItem>
          </ContainerInstructions>
        ) : (
          <ContainerInstructions>
            <InstructionItem text="Apoie o documeno em uma superfice reta">
              <RegSquare />
            </InstructionItem>
            <InstructionItem text="Busque uma boa iluminação">
              <BulbIcon />
            </InstructionItem>
            <InstructionItem text="Ajuste o foco para garantir a qualidade ">
              <Focus />
            </InstructionItem>
            <InstructionItem text="Evite rotacionar a cãmera do dispositivo ">
              <RotatePhoneIcon />
            </InstructionItem>
          </ContainerInstructions>
        )}

        <Button title="Entendi" onClick={onClose} />
      </Content>
    </div>
  );
}
