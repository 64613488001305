import styled, { css } from 'styled-components';
import colors from '../../styles/colors';

interface ContainerProps {
  firstStep: boolean;
  nextSteps: boolean;
}

export const ContentSection = styled.div`
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Content = styled.div<{ expanded?: boolean }>`
  display: flex;
  min-height: 520px;
  min-width: 600px;
  max-width: ${p => (p.expanded ? '900px' : '600px')};
  width: ${p => (p.expanded ? '100%' : 'unset')};
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  border-radius: 4px;
  position: relative;

  background: #fff;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);

  @media (max-width: 600px) {
    height: 100%;
    min-width: 100%;

    div:last-child {
      margin-top: 0;
    }
  }
`;

export const GoBack = styled.button`
  background: transparent;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  margin: 1rem;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }

  color: ${colors.aux1};
  font-weight: bold;
`;

export const Container = styled.div<ContainerProps>`
  position: relative;
  background: linear-gradient(
    180deg,
    rgba(200, 200, 200, 0.3) 0%,
    rgba(240, 240, 240, 0.5) 100%
  );
  padding-bottom: 100px;
  padding-top: 1.5rem;
  min-height: 100vh;
  justify-content: center;

  ${props =>
    props.firstStep &&
    css`
      height: 'calc(var(--vh, 1vh) * 100)';
      height: 100%;
      min-height: 90vh;
      justify-content: space-between;
    `}

  ${props =>
    props.nextSteps &&
    css`
      height: 'calc(var(--vh, 1vh) * 100)';
      height: 100%;
      min-height: 90vh;
      justify-content: center;
    `}
`;

export const UserData = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem;

  display: flex;
  flex-direction: column;

  > p {
    margin-right: 1rem;

    span {
      font-weight: bold;
    }
  }

  button {
    display: flex;
    align-items: center;

    border: 0;
    background: transparent;
    width: fit-content;
    margin-top: 0.5rem;
    color: #ed4c4c;
    svg {
      margin-right: 0.5rem;
    }
  }

  @media (max-width: 600px) {
    position: relative;
    align-self: flex-start;
  }
`;

export const Version = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.6rem;

  p {
    font-weight: bold;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 1.5rem 2rem;

  img {
    height: 100px;
    width: auto;
  }

  @media (max-width: 600px) {
    height: 100px;
    position: relative;

    img {
      height: 50px;
      width: auto;
    }
  }

  div:only-child {
    margin: 0 auto;
  }
`;
