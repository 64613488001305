interface ClientHeightProps {
  vh: number;
  setVh: React.Dispatch<React.SetStateAction<number>>;
}

const setClientHeight = ({ vh, setVh }: ClientHeightProps): void => {
  setVh(window.innerHeight * 0.01);
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

export default setClientHeight;
