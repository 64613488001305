import { cpf } from 'cpf-cnpj-validator';
import { createMask } from 'imask';
import React, { ChangeEvent } from 'react';
import { useForm } from 'react-hook-form';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useSteps } from '../../contexts/stepContext';
import { errorToast } from '../../utils/toast';
import { Content, GoBack } from '../BodyStep/styles';
import Button from '../Button';
import DropDown from '../DropDown';
import Input from '../Input';
import { FormContent, Title, Main } from './styles';
import Camera from '../Camera';

const maskCpf = createMask({
  mask: '000.000.000-00',
});

const maskBirth = createMask({
  mask: '00/00/0000',
});

export default function Identification() {
  const {
    handleChangeCurrentStep,
    documentNum,
    name,
    birthday,
    documentType,
    handleSetDocument,
    handleSetName,
    handleSetBirthday,
    handleReturnCurrentStep,
    setDocumentType,
    documents,
    requiredFields,
    selfie,
    handleSetImage,
    handleChangeCurrentValidation,
    setActionFromImage,
  } = useSteps();

  const { handleSubmit } = useForm();

  const handleValidate = () => {
    if (requiredFields.name === true && name === '') {
      errorToast({ message: 'Nome é obrigatório!' });
      return;
    }

    if (requiredFields.birth_date === true && birthday === '') {
      errorToast({ message: 'Data de nascimento é obrigatório!' });
      return;
    }

    if (requiredFields.image && selfie === '') {
      errorToast({ message: 'Foto é obrigatória!' });
      return;
    }

    if (documentType === 1 || documentType === 0) {
      if (!cpf.isValid(documentNum)) {
        errorToast({ message: 'Documento inválido' });
        return;
      }
      handleChangeCurrentStep();
      return;
    }
    handleChangeCurrentStep();
  };

  const setCpf = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = maskCpf.resolve(e.target.value);
    handleSetDocument(e.target.value);
  };

  const setBirth = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = maskBirth.resolve(e.target.value);
    handleSetBirthday(e.target.value);
  };

  const handleGoBack = () => {
    handleReturnCurrentStep();
    handleSetImage('');
    handleChangeCurrentValidation('');
    setActionFromImage('SELFIE');
  };

  return (
    <Content expanded>
      <GoBack onClick={handleGoBack}>
        <FaArrowLeft size={16} />
        voltar
      </GoBack>
      <Main>
        <div style={{ display: 'grid', placeItems: 'center', width: '100%' }}>
          <Camera />
        </div>

        <div style={{ width: '100%' }}>
          <Title>Dados Pessoais</Title>
          <FormContent onSubmit={handleSubmit(handleValidate)}>
            <Input
              required={requiredFields.name}
              type="text"
              label="Nome Completo"
              onChange={e => handleSetName(e.target.value)}
              value={name}
              // width="100%"
              autoFocus
            />
            <Input
              required={requiredFields.birth_date}
              type="tel"
              label="Data de nascimento"
              onChange={e => setBirth(e)}
              value={birthday}
              width="230px"
            />

            <DropDown
              required
              label="Tipo do documento"
              options={documents}
              onChange={e => {
                setDocumentType(Number(e.target.value));
              }}
              width="230px"
            />
            <Input
              required
              type="tel"
              label="Número"
              onChange={e =>
                documentType === 1
                  ? setCpf(e)
                  : handleSetDocument(e.target.value)
              }
              value={documentNum}
              disabled={documentType === 0}
              width="80%"
            />
            <Button title="Próximo" type="submit" icon={FaArrowRight} />
          </FormContent>
        </div>
      </Main>
    </Content>
  );
}
