import React, { useCallback } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { FaPowerOff } from 'react-icons/fa';
import packageJson from '../../../package.json';
import logo from '../../assets/logo.svg';
import logoSwint from '../../assets/swintLogo.svg';
import { useAuth } from '../../contexts/authContext';
import { useSteps } from '../../contexts/stepContext';
import Steps from '../Steps';
import { Container, ContentSection, Header, UserData, Version } from './styles';

const BodyStep: React.FC = ({ children }) => {
  const { companyName, signOut, userName, apiVersion } = useAuth();
  const { currentStep, clearData } = useSteps();

  const handleSignOut = useCallback(() => {
    clearData();
    signOut();
    window.location.href =
      `${process.env.REACT_APP_URL}/operacional/historicocpf` ||
      'https://swint.imobpower.com/operacional/historicocpf';
  }, [clearData, signOut]);

  return (
    <Container
      firstStep={currentStep === 1 && isMobileOnly}
      nextSteps={currentStep > 1 && isMobileOnly}
    >
      {isMobileOnly ? (
        <>
          {currentStep !== 0 ? (
            <>
              <Header>
                <div>
                  <h1>Identificação</h1>
                  <Steps />
                </div>
              </Header>
              <ContentSection>{children}</ContentSection>
            </>
          ) : (
            <>
              <Header>
                <img src={logo} alt="" />
                <img src={logoSwint} alt="" />
              </Header>
              <ContentSection>{children}</ContentSection>
              <UserData>
                <p>
                  <span>Empresa:</span> {companyName}
                </p>
                <p>
                  <span>Usuário:</span> {userName}
                </p>
                <button onClick={handleSignOut} type="button">
                  <FaPowerOff size={16} />
                  Sair
                </button>
              </UserData>
              <Version>
                <p>Versão: {packageJson.version}</p>
                <p>Versão API: {apiVersion}</p>
              </Version>
            </>
          )}
        </>
      ) : (
        <>
          <Header>
            <img src={logo} alt="" />
            <div>
              <h1>Identificação</h1>
              {currentStep > 0 && <Steps />}
            </div>

            <img src={logoSwint} alt="" />
          </Header>

          <ContentSection>{children}</ContentSection>
          <UserData>
            <p>
              <span>Empresa:</span> {companyName}
            </p>
            <p>
              <span>Usuário:</span> {userName}
            </p>
            <button onClick={handleSignOut} type="button">
              <FaPowerOff size={16} />
              Sair
            </button>
          </UserData>
          <Version>
            <p>Versão: {packageJson.version}</p>
            <p>Versão API: {apiVersion}</p>
          </Version>
        </>
      )}
    </Container>
  );
};

export default BodyStep;
