import { createMask } from 'imask';
import React, { ChangeEvent, useCallback } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import { useSteps } from '../../contexts/stepContext';
import { Title } from '../../styles/global';
import { errorToast } from '../../utils/toast';
import { Content, GoBack } from '../BodyStep/styles';
import Button from '../Button';
import Input from '../Input';
import { FormContent } from './styles';
import { phoneMask } from '../../utils/phoneMask';

const maskZipCode = createMask({
  mask: '00.000-000',
});

export default function AdditionalFields() {
  const {
    zipCode,
    phoneNumber,
    email,
    handleSetZipCode,
    handleSetPhoneNumber,
    handleSetEmail,
    handleChangeCurrentStep,
    handleReturnCurrentStep,
  } = useSteps();

  const { handleSubmit } = useForm();

  const handleValidate = useCallback(() => {
    if (zipCode === '') {
      errorToast({ message: 'CEP é obrigatório!' });
      return;
    }

    if (phoneNumber === '') {
      errorToast({ message: 'Telefone é obrigatório!' });
      return;
    }

    if (email === '') {
      errorToast({ message: 'Email é obrigatório!' });
      return;
    }
    handleChangeCurrentStep();
  }, [handleChangeCurrentStep, email, phoneNumber, zipCode]);

  const setZipCode = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = maskZipCode.resolve(e.target.value);
    handleSetZipCode(e.target.value);
  };

  const setPhoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
    handleSetPhoneNumber(phoneMask(e.target.value));
  };

  return (
    <Content>
      <GoBack onClick={handleReturnCurrentStep}>
        <FaArrowLeft size={16} />
        voltar
      </GoBack>
      <Title>Campos Adicionais</Title>
      <FormContent onSubmit={handleSubmit(handleValidate)}>
        <Input
          required
          type="text"
          label="CEP"
          onChange={e => setZipCode(e)}
          value={zipCode}
          width="40%"
          autoFocus
        />
        <Input
          required
          type="tel"
          label="Telefone"
          onChange={e => setPhoneNumber(e)}
          value={phoneNumber}
          width="40%"
        />

        <Input
          required
          type="email"
          label="E-Mail"
          onChange={e => handleSetEmail(e.target.value)}
          value={email}
          width="50%"
        />

        <Button title="Próximo" type="submit" icon={FaArrowRight} />
      </FormContent>
    </Content>
  );
}
