import styled from 'styled-components';

const colors = {
  primary: 'rgba(0, 0, 0, 1)',
  secondary: 'rgba(255, 255, 255, 1)',
  background_main: {
    yellow_light: 'rgba(255,188,3, 0.3)',
    yellow: 'rgba(255, 188, 3, 1)',
    green: '#4BA635',
    green_light: '#4fbe79',
    limegreen: '#7FFF00',
    blue_weak: 'rgba(0, 78, 255, 1)',
    gray_strong: 'rgba(45, 46, 54, 1)',
    gray_strong_1: '#999999',
    gray_strong_2: '#626262',
    gray_strong_3: '#333333',
    gray_light_1: 'rgba(45, 46, 54, 0.3)',
    gray_light_2: 'rgba(0, 0, 0, 0.3)',
    gray_light_3: 'rgba(50,50,50,1)',
    gray_light_4: 'rgba(0, 0, 0, 0.9)',
    red_light: '#FF5F5F',
    red_light_1: '#ff8989',
    red_strong: '#F00000',
    white_100: 'white',
    white_1: '#efefef',
    white_2: '#e6e6e6',
  },
  badges: {
    blue_strong: 'rgba(0, 78, 135, 1)',
  },
  borders: {
    yellow: '1px solid rgba(255, 188, 3, 1)',
    gray_light_border: '1px solid rgba(220, 220, 220)',
    gray_border: '1px solid #c1c1c1',
    gray_light_border_1: '1px solid rgba(0, 0, 0, 0.2)',
    red_light_border: '1px solid #FF5F5F',
  },
  headers: {
    gray_light_header: 'rgba(220, 220, 220, 0.4)',
  },
  inputs: {
    background: 'rgba(240, 240, 240, 0.6)',
  },
};

interface SizeProps {
  width: string;
}

export const Container = styled.div<SizeProps>`
  width: ${p => p.width};

  display: flex;
  flex-direction: column;
  gap: 1rem;

  small {
    text-align: right;
  }

  @media (max-width: 850px) {
    width: 100%;
  }
`;

export const CheckBoxWrapper = styled.div<SizeProps>`
  display: flex;
  justify-content: space-between;

  @media (max-width: 850px) {
    grid-template-columns: 54px 15%;
  }
`;

export const CheckBoxLabel = styled.label`
  top: calc(50% - 4px);
  right: 0;
  width: 32px;
  height: 16px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
export const CheckBox = styled.input`
  position: absolute;
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 0px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: ${colors.background_main.green_light};
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

export const CheckboxTitle = styled.span`
  display: block;
  font-size: 1rem;
  text-align: right;
`;
