import api from './api';

export interface IDocuments {
  typ_id: number;
  typ_description: string;
}
export interface DocumentsSelect {
  id: number;
  label: string;
}

const getDocuments = async (): Promise<DocumentsSelect[]> => {
  const { data } = (await api
    .get('/docs')
    .then(response => response)
    .catch(err => err.response)) as { data: { documents: IDocuments[] } };

  const documents = [] as DocumentsSelect[];

  data.documents.forEach(el => {
    documents.push({ id: el.typ_id, label: el.typ_description });
  });

  return documents;
};

export { getDocuments };
