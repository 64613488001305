import React, { ReactNode } from 'react';
import { Container, Text } from './styles';

interface ItemProps {
  children: ReactNode;
  text: string;
}

const InstructionItem: React.FC<ItemProps> = ({
  text,
  children,
}: ItemProps): React.ReactElement => {
  return (
    <Container>
      {children}
      <Text>{text}</Text>
    </Container>
  );
};

export default InstructionItem;
