import { useCallback, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { Document, Page, pdfjs } from 'react-pdf';
import { useAuth } from '../../contexts/authContext';
import { useSteps } from '../../contexts/stepContext';
import { getParamQuery } from '../../services/optIn';
import { Title } from '../../styles/global';
import { AlertBox } from '../AlertBox';
import { Content } from '../BodyStep/styles';
import Button from '../Button';
import Loading from '../Loading';
import {
  BadgeResult,
  MessageError,
  PdfPreview,
  ProfileContent,
  ProfileData,
  ResultContent,
} from './styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function Result() {
  const [optinText, setOptinText] = useState('');

  const {
    loadingResult,
    selfie,
    name,
    documentNum,
    clearData,
    result,
    messageError,
    setMessageError,
    handleReturnCurrentStep,
    serviceError,
    setCurrentStep,
  } = useSteps();

  const { accessDash, signOut, canSelectServices } = useAuth();

  const handleTryAgain = () => {
    handleReturnCurrentStep();
    setMessageError('');
  };

  const returnToPlatform = useCallback(() => {
    signOut();
    window.location.href =
      `${process.env.REACT_APP_URL}/operacional/historicocpf` ||
      'https://swint.imobpower.com/operacional/historicocpf';
  }, [signOut]);

  useEffect(() => {
    getParamQuery().then(response => setOptinText(response.param.par_valor));
  }, []);

  const handleReturnToStart = () => {
    clearData();
    setCurrentStep(canSelectServices ? 1 : 0);
  };

  return (
    <Content>
      {loadingResult ? (
        <Loading withOverlay={false} />
      ) : (
        <>
          {serviceError && <BadgeResult>{serviceError}</BadgeResult>}
          <Title>Resultado</Title>
          <ProfileContent>
            {!isMobileOnly && selfie.startsWith('data:image/') && (
              <img src={selfie} alt="" />
            )}
            {!isMobileOnly && selfie.startsWith('data:application/pdf') && (
              <PdfPreview>
                <Document file={selfie}>
                  <Page
                    pageNumber={1}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                  />
                </Document>
              </PdfPreview>
            )}
            <ProfileData>
              {name && (
                <>
                  <p>Nome Completo</p>
                  <span>{name}</span>{' '}
                </>
              )}
              <p>Documento</p>
              <span>{documentNum}</span>
              <ResultContent mode={result}>
                {result === 'success' && (
                  <p>
                    <FaCheck size={24} color="#fff" />
                    RECOMENDADO
                  </p>
                )}
                {result === 'error' && (
                  <p>
                    <FaTimes size={24} color="#fff" />
                    NÃO RECOMENDADO
                  </p>
                )}
                {result === 'warn' && (
                  <p>
                    <FaTimes size={24} color="#fff" />
                    FALHA
                  </p>
                )}
              </ResultContent>
            </ProfileData>
          </ProfileContent>
          <MessageError>{result === 'warn' && messageError}</MessageError>
          <AlertBox message={optinText} removeAnimation />
          {accessDash ? (
            <Button
              title="Voltar a plataforma SGRLOC"
              onClick={returnToPlatform}
              style={{ marginTop: 20 }}
            />
          ) : (
            <Button
              title={
                result === 'warn' ? 'Tentar novamente' : 'Voltar ao Início'
              }
              onClick={result === 'warn' ? handleTryAgain : handleReturnToStart}
              style={{ marginTop: 20 }}
            />
          )}
        </>
      )}
    </Content>
  );
}
