import api from './api';

interface IResponse {
  result: string;
  message: string;
}

interface IGetOptIn extends IResponse {
  param: {
    par_cmp_id: number;
    par_valor: string;
    par_description: string;
  };
}

const getOptIn = async (): Promise<IGetOptIn> => {
  const { data } = await api
    .post('/config/searchOne', {
      par_tipo: 'param_optin_text',
    })
    .then(response => response)
    .catch(err => err.response);

  return data;
};

const getParamQuery = async (): Promise<IGetOptIn> => {
  const { data } = await api
    .post('/config/searchOne', {
      par_tipo: 'param_balcao_query',
    })
    .then(response => response)
    .catch(err => err.response);

  return data;
};
const acceptOptin = async (user_Email: string): Promise<IResponse> => {
  const { data } = await api
    .put('/users/optin', { cre_user: user_Email })
    .then(response => response)
    .catch(error => error.response);
  return data;
};

export { acceptOptin, getOptIn, getParamQuery };
