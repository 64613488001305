import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;

  border: none;
  border-radius: 2rem;
  font-weight: 900;
  color: #fff;
  background: ${colors.aux1};
  font-size: 1rem;
  line-height: 1.5rem;
  height: 50px;
  padding: 0.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: filter 0.2s;
  &:disabled {
    cursor: not-allowed;
    background: rgba(255, 188, 3, 0.3);
  }
  &:hover {
    filter: drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.5));
  }

  svg {
    margin-left: 0.5rem;
  }
`;
export const Loading = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin-left: 0.5rem;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
