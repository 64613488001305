import styled from 'styled-components';

interface ContainerProps {
  width: string;
}

export const Container = styled.div<ContainerProps>`
  width: ${props => props.width};
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem;

  @media (max-width: 850px) {
    width: 100%;
  }

  label {
    width: 100%;
    text-align: center;
    font-weight: bold;
  }

  select {
    width: 100%;
    padding: 0.5rem;
    border: 0;
    border-bottom: 1px solid #000;
    text-align: center;
    margin-top: 0.5rem;
  }
`;
