import React from 'react';
import loader from '../../assets/loading.svg';
import { Overlay } from './styles';

interface LoadingProps {
  withOverlay?: boolean;
}

const Loading: React.FC<LoadingProps> = ({ withOverlay = true }) => {
  return (
    <>
      {withOverlay ? (
        <Overlay>
          <img src={loader} alt="Carregando..." />
        </Overlay>
      ) : (
        <img src={loader} alt="Carregando..." />
      )}
    </>
  );
};

export default Loading;
