import styled from 'styled-components';

export const Container = styled.section`
  height: 100%;
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 1px 10px rgba(0, 0, 0, 0.2);

  section {
    width: 100%;
    height: 100%;
    p {
      width: 100%;
      height: 100%;
      background-color: #c4c4c4;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-weight: bold;

      svg {
        color: #fff;
        width: 32px;
        height: 32px;
        margin-bottom: 16px;
      }
    }
  }
  span {
    font-size: 20px;
    padding: 16px 0;
  }
  img {
    background: #c4c4c4;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
