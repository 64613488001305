import { Redirect, Switch } from 'react-router-dom';
import Balcao from '../pages/Balcao';
import ForgotPassword from '../pages/ForgotPassword';
import GeolocationDenied from '../pages/GeolocationDenied';
import Login from '../pages/Login';
import ValidateToken from '../pages/ValidateToken';
import Route from './Route';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/forgotPassword" exact component={ForgotPassword} />
      <Route path="/geolocationDenied" exact component={GeolocationDenied} />
      <Route path="/validateToken/:token" exact component={ValidateToken} />
      <Route path="/balcao" exact component={Balcao} isPrivate />
      <Route path="/*" component={() => <Redirect to="/" />} />
    </Switch>
  );
};

export default Routes;
