import styled from 'styled-components';

export const Content = styled.div`
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  background: #fff;
  padding: 1.5rem;
  border-radius: 1rem;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);

  img {
    width: 75px;
  }

  @media (max-width: 600px) {
    width: 90%;

    img {
      width: 80px;
    }

    form {
      width: 90%;
    }
  }
`;
