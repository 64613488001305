import { useContext } from 'react';
import StepContext from './context';
import { StepContextData } from './types';

export default function useSteps(): StepContextData {
  const context = useContext(StepContext);

  if (!context) {
    throw new Error(
      "Custom hook 'useSteps' must be used within an 'StepProvider'",
    );
  }

  return context;
}
