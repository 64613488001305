import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import Webcam from 'react-webcam';
import maskCNH from '../../assets/cnhCrop.svg';
import maskFace from '../../assets/maskCam.png';
import AdditionalFields from '../../components/AdditionalFields';
import Button from '../../components/Button';
import { Crop } from '../../components/Camera/styles';
import Dropzone from '../../components/Dropzone';
import Identification from '../../components/Identification';
import Loading from '../../components/Loading';
import Result from '../../components/Result';
import ServicesSelection from '../../components/ServicesSelection';
import api from '../../services/api';
import { DocumentsSelect, getDocuments } from '../../services/documents';
import { RequiredFields } from '../../services/services';
import { MobileCam } from '../../styles/global';
import { useAuth } from '../authContext';
import StepContext from './context';
import { Service, StepContextData, ValidationResponse } from './types';

export default function StepProvider({ children }: { children: ReactNode }) {
  const history = useHistory();
  const { additionalFields, canSelectServices } = useAuth();

  const lifeProof = localStorage.getItem('@SWINTbalcao:lifeProof');
  const webcamRef = useRef<Webcam>();

  const [currentStep, setCurrentStep] = useState(0);
  const [name, setName] = useState('');
  const [documentNum, setDocumentNum] = useState('');
  const [selfie, setImage] = useState('');
  const [currentValidation, setCurrentValidation] = useState('');
  const [birthday, setBirthday] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [result, setResult] = useState('');
  const [messageError, setMessageError] = useState('');
  const [documentType, setDocumentType] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingResult, setLoadingResult] = useState(true);
  const [actionFromImage, setActionFromImage] = useState('SELFIE');
  const [serviceError, setServiceError] = useState('');
  const [documents, setDocuments] = useState<DocumentsSelect[]>([]);
  const [selectedServices, setSelectedServices] = useState<Service[]>([]);
  const [requiredFields, setRequiredFields] = useState<RequiredFields>({
    name: false,
    birth_date: false,
    image: true,
  });
  const [imageJwt, setImageJwt] = useState('');

  const steps = useMemo(() => {
    const initial = [Identification, Result];

    let addFields = false;

    if (selectedServices.length === 0) {
      addFields = additionalFields;
    } else {
      addFields = selectedServices.some(s => s.ser_additional_fields === true);
    }

    if (addFields) {
      initial.splice(1, 0, AdditionalFields);
    }

    if (canSelectServices) {
      initial.unshift(ServicesSelection);
    }

    return initial;
  }, [selectedServices, canSelectServices, additionalFields]);

  const handleChangeCurrentStep = useCallback(() => {
    setCurrentStep(prev => prev + 1);
  }, []);

  const handleReturnCurrentStep = useCallback(() => {
    setLoadingResult(true);
    setCurrentStep(prev => prev - 1);
  }, []);

  const capture = () => {
    const imageSrc = webcamRef.current?.getScreenshot() || '';
    setImage(imageSrc);
  };

  const clearData = useCallback(() => {
    setDocumentNum('');
    setImage('');
    setName('');
    setBirthday('');
    setZipCode('');
    setPhoneNumber('');
    setEmail('');
    setCurrentValidation('');
    setMessageError('');
    setResult('');
    setServiceError('');
    setLoadingResult(true);
  }, []);

  const ImageToBase64 = useCallback((file: File): void => {
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (): void => {
      setImage(reader.result as string);
    };
    reader.onerror = () => {
      return false;
    };
  }, []);

  const sendRequest = useCallback(async () => {
    const { data } = await api.post<ValidationResponse>('validationrequest', {
      document: documentNum,
      selfie,
      birth_date: birthday,
      name,
      document_type: documentType,
      zip_code: zipCode || undefined,
      phone_number: phoneNumber || undefined,
      email: email || undefined,
      imageJwt,
      api_key: process.env.REACT_APP_API_KEY_UNICO,
      selected_services: selectedServices.map(s => s.cos_ser_id),
    });
    if (data.result) setResult(data.result);
    if (data.message) setMessageError(data.message);
    if (data.service) setServiceError(data.service);
    setLoadingResult(false);
  }, [
    documentNum,
    selfie,
    birthday,
    name,
    documentType,
    zipCode,
    phoneNumber,
    email,
    imageJwt,
    selectedServices,
  ]);

  useEffect(() => {
    const onLocationFailed = () => {
      setLoading(false);
      history.push('/geolocationDenied');
    };

    const getUserLocation = () => {
      setLoading(true);
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(position => {
          const { latitude, longitude } = position.coords;

          localStorage.setItem('@SWINTbalcao:latitude', String(latitude));
          localStorage.setItem('@SWINTbalcao:longitude', String(longitude));
          api.defaults.headers.common.lat = latitude;
          api.defaults.headers.common.lng = longitude;
          api.defaults.headers.common.cmpid = '';
          api.defaults.headers.common.plcid = '';
          setLoading(false);
        }, onLocationFailed);
      } else {
        onLocationFailed();
      }
    };

    const getOptions = () => {
      getDocuments().then(response => {
        if (response) setDocuments(response);
      });
    };

    getUserLocation();
    getOptions();
  }, [history]);

  useEffect(() => {
    if (currentStep === 0) {
      clearData();
    }
  }, [currentStep, clearData]);

  useEffect(() => {
    if (currentStep === steps.length - 1) {
      sendRequest();
    }
  }, [steps.length, currentStep, sendRequest]);

  const value = useMemo<StepContextData>(
    () => ({
      loading,
      documentNum,
      name,
      birthday,
      zipCode,
      phoneNumber,
      email,
      selfie,
      currentStep,
      result,
      documentType,
      selectedServices,
      requiredFields,
      steps,
      loadingResult,
      setRequiredFields,
      handleSetDocument: setDocumentNum,
      handleSetName: setName,
      handleSetBirthday: setBirthday,
      handleSetZipCode: setZipCode,
      handleSetPhoneNumber: setPhoneNumber,
      handleSetEmail: setEmail,
      handleSetImage: setImage,
      handleSetLoading: setLoading,
      handleChangeCurrentValidation: setCurrentValidation,
      setCurrentStep,
      setResult,
      setDocumentType,
      setMessageError,
      setActionFromImage,
      setServiceError,
      setSelectedServices,
      handleChangeCurrentStep,
      handleReturnCurrentStep,
      clearData,
      ImageToBase64,
      currentValidation,
      messageError,
      actionFromImage,
      serviceError,
      documents,
      imageJwt,
      setImageJwt,
      sendRequest,
    }),
    [
      loading,
      loadingResult,
      documentNum,
      name,
      birthday,
      zipCode,
      phoneNumber,
      email,
      selfie,
      currentStep,
      result,
      documentType,
      selectedServices,
      requiredFields,
      steps,
      handleChangeCurrentStep,
      handleReturnCurrentStep,
      clearData,
      ImageToBase64,
      currentValidation,
      messageError,
      actionFromImage,
      serviceError,
      documents,
      imageJwt,
      sendRequest,
    ],
  );

  return (
    <StepContext.Provider value={value}>
      {isMobile &&
      (additionalFields ? currentStep === 5 : currentStep === 4) &&
      !selfie &&
      (lifeProof === 'false' ||
        (lifeProof === 'true' && currentValidation !== 'Foto')) ? (
        <MobileCam>
          <section>
            {actionFromImage === 'SELFIE' && (
              <>
                <Webcam
                  audio={false}
                  ref={webcamRef as any}
                  screenshotQuality={1}
                  screenshotFormat="image/jpeg"
                  videoConstraints={{
                    facingMode:
                      currentValidation === 'Foto' ? 'user' : 'environment',
                  }}
                  style={{
                    position: 'relative',
                    height: '100%',
                    width: '100%',
                    background: 'rgba(0, 0, 0, 0.85)',
                  }}
                />
                <Crop>
                  {currentValidation === 'Foto' ? (
                    <img src={maskFace} alt="" height="80%" />
                  ) : (
                    <img src={maskCNH} alt="" height="98%" />
                  )}
                </Crop>
              </>
            )}
            {actionFromImage === 'UPLOAD' && <Dropzone />}
          </section>
          {currentValidation === 'CNH' && actionFromImage === 'SELFIE' && (
            <Button
              title="UPLOAD DE IMAGEM"
              onClick={() => setActionFromImage('UPLOAD')}
            />
          )}
          {actionFromImage === 'UPLOAD' && (
            <Button
              title="CAMERA"
              onClick={() => setActionFromImage('SELFIE')}
            />
          )}
          {actionFromImage === 'SELFIE' && (
            <Button
              title="TIRAR FOTO"
              onClick={capture}
              style={{ alignSelf: 'center' }}
            />
          )}
        </MobileCam>
      ) : (
        <>{children}</>
      )}
      {loading && <Loading />}
    </StepContext.Provider>
  );
}
