import { ReactElement, useCallback, useState } from 'react';
import {
  CloseBox,
  CloseIcon,
  ContainerBox,
  IconBox,
  InfoIcon,
  MessageBox,
  WarningIcon,
  Wrapper,
} from './styles';

type AlertProps = {
  context?: 'warning' | 'info' | 'success' | 'error';
  message?: string;
  removeAnimation?: boolean;
};

const AlertBox = ({
  context = 'info',
  message,
  removeAnimation = false,
}: AlertProps): ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isNone, setIsNone] = useState<boolean>(false);

  const handleDisableComponent = useCallback(() => {
    setTimeout(() => setIsNone(true), 500);
  }, []);

  return (
    <Wrapper
      isOpen={isOpen}
      disabled={isNone}
      removeAnimation={removeAnimation}
    >
      <ContainerBox>
        <IconBox>
          {context === 'warning' && <WarningIcon size="20px" />}
          {context === 'info' && <InfoIcon size="20px" />}
          {context === 'success' && <WarningIcon size="20px" />}
          {context === 'error' && <WarningIcon size="20px" />}
        </IconBox>
        <MessageBox>{message?.replace('\\n', '\n') || ''}</MessageBox>
        {!removeAnimation && (
          <CloseBox>
            <CloseIcon
              size="20px"
              role="button"
              onClick={() => {
                setIsOpen(prevState => !prevState);
                handleDisableComponent();
              }}
            />
          </CloseBox>
        )}
      </ContainerBox>
    </Wrapper>
  );
};

export { AlertBox };
