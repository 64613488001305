import styled from 'styled-components';
import backgroundLoginImg from '../../assets/background.jpeg';

export const Container = styled.div`
  position: absolute;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;
export const ContainerCard = styled.div`
  min-height: 620px;
  width: 80%;
  justify-content: center;
  border-radius: 8px;

  background-image: url(${backgroundLoginImg});
`;
export const ContainerContent = styled.div`
  width: 100%;
  padding: 0 64px;
`;
export const Content = styled.div`
  width: 100%;
  height: 300px;
  overflow-y: auto;
  padding: 16px;
  white-space: pre-wrap;
`;
export const TitleContent = styled.p``;
export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 10px;
  padding: 32px;
`;
export const Box = styled.div`
  border-radius: 8px;
  background: linear-gradient(
    180deg,
    rgba(200, 200, 200, 0.75) 0%,
    rgba(240, 240, 240, 0.75) 100%
  ); ;
`;
export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 32px;
  padding: 56px;
  color: rgba(45, 46, 54, 1);
`;
export const ContainerCheckBox = styled.div`
  display: flex;
  margin-top: 20px;
`;
export const TitleCheckBox = styled.p`
  margin-left: 25px;
`;
