export default {
  primary: 'rgba(0, 0, 0, 1)',
  secondary: 'rgba(255, 255, 255, 1)',
  aux1: 'rgba(255, 188, 3, 1)',
  aux1_light: 'rgba(255,188,3, 0.3)',
  aux2: 'rgba(57, 156, 76, 1)',
  aux3: 'rgba(0, 78, 255, 1)',
  aux3_badge: 'rgba(0, 78, 135, 1)',
  aux_primary: 'rgba(45, 46, 54, 1)',
};
