import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
  background: linear-gradient(
    180deg,
    rgba(200, 200, 200, 0.3) 0%,
    rgba(240, 240, 240, 0.5) 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100vh;

  > img {
    max-height: 100px;
    margin-bottom: 1rem;
  }

  @media (max-width: 600px) {
    > img {
      height: auto;
      width: 180px;
    }
  }
`;

export const Content = styled.div`
  min-width: 30%;
  max-width: 540px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);

  p {
    margin-top: 2rem;
    font-size: 1.25rem;
    text-align: center;
  }

  span {
    color: ${colors.aux1};
  }

  img {
    width: 100px;
  }

  a {
    margin-top: 2rem;
    color: inherit;
    transition: all 0.2s;

    &:hover {
      color: ${colors.aux1};
    }
  }

  @media (max-width: 600px) {
    width: 90%;

    img {
      width: 80px;
    }

    form {
      width: 90%;
    }
  }
`;
