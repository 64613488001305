import { IoMdWarning } from 'react-icons/io';
import { RiCloseLine, RiErrorWarningFill } from 'react-icons/ri';
import styled from 'styled-components';

type WrapperProp = {
  isOpen: boolean;
  disabled: boolean;
  removeAnimation: boolean;
};

export const Wrapper = styled.div<WrapperProp>`
  transition: 0.5s;
  /* transform: translateY(${props => (props.isOpen ? '0' : '-50vh')});
  transform: translateY(${props => props.removeAnimation && '0'}); */
  background-color: hsl(39 97% 62% / 0.1);
  box-shadow: 0 0 0 1px hsl(37, 90%, 51%);
  color: hsl(37, 90%, 51%);
  border-radius: 2px;
  margin-top: ${props => props.removeAnimation && '1rem'};
  width: ${props => (!props.removeAnimation ? '500px' : '100%')};
  display: ${props => props.disabled && 'none'};
  /* position: ${props => !props.removeAnimation && 'absolute'}; */
`;

export const ContainerBox = styled.div`
  display: grid;
  place-items: center;
  grid-template-columns: 50px 1fr 25px;
  gap: 1rem;
  background-color: papayawhip;
`;

export const IconBox = styled.span`
  height: 100%;
  width: 100%;

  display: grid;
  place-items: center;
  background-color: hsl(37, 90%, 51%);
`;

export const CloseBox = styled.span`
  padding: 0.5rem 0;
  align-self: start;
`;

export const MessageBox = styled.p`
  text-align: left;
  color: black;
  font-size: 12px;
  white-space: pre-line;
  padding: 1rem 0;
`;

export const WarningIcon = styled(IoMdWarning)`
  color: white;
`;

export const InfoIcon = styled(RiErrorWarningFill)`
  color: white;
`;

export const CloseIcon = styled(RiCloseLine)`
  color: red;

  :hover {
    cursor: pointer;
  }
`;

interface StyledAlertPortalProps {
  isOpen: boolean;
}

export const StyledAlertPortal = styled.div<StyledAlertPortalProps>`
  position: fixed;
  left: 0;
  width: 100%;
  height: auto;
  z-index: ${props => (props.isOpen ? '999' : '-1')};
  padding: 1rem 6rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
