import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/authContext';
import { acceptOptin, getOptIn } from '../../services/optIn';
import { errorToast } from '../../utils/toast';
import Button from '../Button';
import RoundedCheckbox from '../Input/RoundedCheckbox';
import {
  Box,
  Container,
  ContainerButton,
  ContainerCard,
  ContainerCheckBox,
  ContainerContent,
  Content,
  Header,
  Title,
  TitleCheckBox,
} from './styles';

const OptIn: React.FC = () => {
  const [checked, setChecked] = useState(false);
  const { user, setData, signOut } = useAuth();
  const [optinText, setOptinText] = useState<string>('');

  const handleRoute = async () => {
    const res = await acceptOptin(user);
    if (res.result === 'success') {
      setData(oldValue => {
        return {
          ...oldValue,
          optin: 1,
        };
      });
    } else {
      errorToast({ message: res.message });
    }
  };
  useEffect(() => {
    getOptIn().then(response => {
      setOptinText(response.param.par_valor);
    });
  }, []);

  return (
    <Container>
      <ContainerCard>
        <Box>
          <Header>
            <Title>Termos de Uso</Title>
          </Header>
          <ContainerContent>
            <ContainerContent>
              <Content>{optinText}</Content>
            </ContainerContent>
            <ContainerCheckBox>
              <RoundedCheckbox
                id="checked"
                isChecked={checked}
                onClick={() => setChecked(!checked)}
              />
              <TitleCheckBox>
                Eu li e concordo com estes termos e condições
              </TitleCheckBox>
            </ContainerCheckBox>
            <ContainerButton>
              <Button
                title="Recusar"
                onClick={() => {
                  signOut();
                }}
              />
              <Button
                onClick={handleRoute}
                title="Aceitar"
                style={{ marginLeft: 10 }}
                disabled={!checked}
              />
            </ContainerButton>
          </ContainerContent>
        </Box>
      </ContainerCard>
    </Container>
  );
};

export default OptIn;
