import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

export const StyledToastContainer = styled(ToastContainer)`
  .Toastify {
    position: absolute;
    z-index: 9999;
  }

  .Toastify__toast--success {
    background: #fff;
    color: #0c6f32;

    .Toastify__close-button > svg {
      fill: #0c6f32;
    }

    .Toastify__progress-bar {
      background: #0c6f32;
    }
  }

  .Toastify__toast--error {
    background: #fff;
    color: tomato;

    .Toastify__close-button > svg {
      fill: tomato;
    }

    .Toastify__progress-bar {
      background: tomato;
    }
  }

  .Toastify__toast--info {
    background: rgba(255, 188, 3, 1);
  }
`;

export const WrapperToastContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.4rem;

  > p {
    width: 100%;
    font-size: 16px;
  }
`;
