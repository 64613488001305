/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ReactElement } from 'react';
import { ContainerCheck, Round } from './styles';

interface CheckboxProps extends React.HTMLAttributes<HTMLInputElement> {
  id?: string;
  isChecked?: boolean;
  value?: number | string;
}

const RoundedCheckbox = ({
  id,
  isChecked,
  value,

  ...rest
}: CheckboxProps): ReactElement => {
  return (
    <ContainerCheck>
      <Round>
        <input
          type="checkbox"
          id={id}
          name={id}
          checked={isChecked}
          value={value}
          {...rest}
        />
        <label htmlFor={id} />
      </Round>
    </ContainerCheck>
  );
};

export default RoundedCheckbox;
