import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.section`
  height: auto;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    width: 120px;
    text-align: left;
    margin: 0 1rem;
  }

  svg {
    color: ${colors.aux1};
  }
`;

export const Text = styled.p`
  font-size: 1rem;
  line-height: 20px;
  text-align: center;
  color: #626262;
  height: auto;
`;
