import { useCallback, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Webcam from 'react-webcam';
import { Page, pdfjs, Document } from 'react-pdf';
import maskCNH from '../../assets/cnhCrop.svg';
import maskFace from '../../assets/maskCam.png';
import { useSteps } from '../../contexts/stepContext';
import Button from '../Button';
import Dropzone from '../Dropzone';
import { Actions, CamSection, Crop, Image, PdfPreview } from './styles';
import DropDown from '../DropDown';
import Zoom from '../Zoom';
import { Title } from '../Identification/styles';
import Instructions from '../Instructions';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function Camera() {
  const [showInstructions, setShowInstructions] = useState(false);

  const webcamRef: any = useRef(null);

  const {
    selfie,
    currentValidation,
    actionFromImage,
    handleSetImage,
    setActionFromImage,
    handleChangeCurrentValidation,
  } = useSteps();

  const videoConstraints = {
    facingMode: 'user',
  };

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    handleSetImage(imageSrc);
  };

  const handleDeleteImage = useCallback(() => {
    handleSetImage('');
  }, [handleSetImage]);

  const handleType = (value: string) => {
    handleChangeCurrentValidation(value);
    handleSetImage('');
    setActionFromImage('SELFIE');
  };

  return (
    <>
      <Title>Foto</Title>
      <div style={{ display: 'flex', width: '90%', alignItems: 'end' }}>
        <DropDown
          style={{ flex: 2 }}
          label="Tipo do documento"
          value={currentValidation}
          options={[
            { id: 'CNH', label: 'CNH / Passaporte' },
            { id: 'Foto', label: 'Selfie' },
          ]}
          onChange={e => {
            handleType(e.target.value);
          }}
        />
        <button
          type="button"
          style={{
            // flex: 1,
            height: '30px',
            width: '30px',
            color: 'rgb(255,188,3)',
            backgroundColor: 'transparent',
            fontSize: '22px',
            border: '1px solid rgb(255,188,3)',
            borderRadius: '50%',
            marginBottom: '1.2rem',
          }}
          onClick={() => setShowInstructions(true)}
        >
          ?
        </button>
      </div>
      <CamSection photo={!!selfie}>
        {selfie.startsWith('data:application/pdf') && (
          <Zoom scale={2} width={225} height={318}>
            <PdfPreview>
              <Document file={selfie}>
                <Page
                  pageNumber={1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              </Document>
            </PdfPreview>
          </Zoom>
        )}

        {selfie.startsWith('data:image') && (
          <Zoom scale={2} width={800} height={600}>
            <Image id="photo" src={selfie} alt="" />
          </Zoom>
        )}

        {!selfie && (
          <>
            {actionFromImage === 'SELFIE' && (
              <>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotQuality={1}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                />
                <Crop>
                  {currentValidation === 'CNH' && (
                    <img src={maskCNH} alt="" height="auto" width="200px" />
                  )}
                  {currentValidation === 'Foto' && (
                    <img src={maskFace} alt="" height="70%" />
                  )}
                </Crop>
              </>
            )}
            {actionFromImage === 'UPLOAD' && <Dropzone />}
          </>
        )}
      </CamSection>
      {currentValidation ? (
        <Actions mobile={isMobile}>
          {selfie ? (
            <Button title="LIMPAR" onClick={handleDeleteImage} />
          ) : (
            <>
              {currentValidation === 'CNH' && actionFromImage === 'SELFIE' && (
                <Button
                  title="UPLOAD DE IMAGEM"
                  onClick={() => setActionFromImage('UPLOAD')}
                />
              )}
              {actionFromImage === 'UPLOAD' && (
                <Button
                  title="CAMERA"
                  onClick={() => setActionFromImage('SELFIE')}
                />
              )}
              {actionFromImage === 'SELFIE' && (
                <Button title="TIRAR FOTO" onClick={capture} />
              )}
            </>
          )}
        </Actions>
      ) : (
        <div style={{ height: '50px' }} />
      )}

      {showInstructions && (
        <Instructions onClose={() => setShowInstructions(false)} />
      )}
    </>
  );
}
