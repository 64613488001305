import React, { ReactElement, ReactNode } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { WrapperToastContent } from './styles';

interface ToastProps {
  children: ReactNode;
}

interface ToastParams {
  message: string;
  options?: ToastOptions | undefined;
}

const ToastComponent: React.FC<ToastProps> = ({ children }): ReactElement => (
  <WrapperToastContent>
    <p>{children}</p>
  </WrapperToastContent>
);

const successToast = ({
  message,
  options = { autoClose: 8000 },
}: ToastParams): React.ReactText =>
  toast.success(<ToastComponent>{message}</ToastComponent>, options);
const warnToast = ({
  message,
  options = { autoClose: 8000 },
}: ToastParams): React.ReactText =>
  toast.warn(<ToastComponent>{message}</ToastComponent>, options);

const errorToast = ({
  message,
  options = { autoClose: 8000 },
}: ToastParams): React.ReactText =>
  toast.error(<ToastComponent>{message}</ToastComponent>, options);

export { successToast, errorToast, warnToast };
