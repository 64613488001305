import { useCallback, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppProvider from './contexts';
import { AuthProvider } from './contexts/authContext';
import Routes from './routes';
import GlobalStyle from './styles/global';
import setClientHeight from './setHeightClient';
import { StyledToastContainer } from './utils/toast/styles';

const App: React.FC = () => {
  const [vh, setVh] = useState(0);

  const clientHeight = useCallback(() => {
    setClientHeight({ vh, setVh });
  }, [vh]);

  useEffect(() => {
    window.addEventListener('resize', clientHeight);
    clientHeight();
    return () => {
      window.removeEventListener('resize', clientHeight);
    };
  }, [clientHeight]);

  return (
    <BrowserRouter>
      <AuthProvider>
        <AppProvider>
          <GlobalStyle />
          <Routes />
        </AppProvider>
      </AuthProvider>
      <StyledToastContainer position="top-center" autoClose={8000} />
    </BrowserRouter>
  );
};

export default App;
